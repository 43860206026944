import isEmpty from 'lodash/isEmpty'

/* The current page and the parent page always have the same pageType */
export const intermediaryPath = ({ pageType, parentPageInfos }) => {
  if (isEmpty(parentPageInfos)) return []
  const { titleBreadcrumb: title, pageName } = parentPageInfos

  return [
    {
      title,
      slug: {
        name: pageType,
        params: {
          pageName,
        },
      },
    },
  ]
}
