// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._191xDa5JZBS7JDt95v4nqB{color:var(--text-primary-light);font-size:1.6rem;font-weight:300;width:100%}._191xDa5JZBS7JDt95v4nqB div>*{max-width:100%;padding-left:0;padding-right:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rteParserPrimaryLight": "_191xDa5JZBS7JDt95v4nqB"
};
module.exports = ___CSS_LOADER_EXPORT___;
