
import { RevIllustration, RevSkeleton } from '@backmarket/design-system'

import { trackClick } from '@tracking/events'

import {
  IMAGES_ENDPOINT,
  TOTAL_NUMBER_OF_BAD_CARD_IMAGES,
  TOTAL_NUMBER_OF_CARDS_TO_DISPLAY,
  TOTAL_NUMBER_OF_GOOD_CARD_IMAGES,
  TrackingData,
} from './catcha.constants'
import translations from './catcha.translations'
import { createCardsToDisplay, isSolved, pickIntegerBetween } from './helpers'

export default {
  components: { RevIllustration, RevSkeleton },

  data() {
    return {
      cards: [],
      numberOfGoodCardsToDisplay: 2,
      loadedCards: [],
      isLoading: true,
    }
  },

  computed: {
    translations: () => translations,
    TOTAL_NUMBER_OF_CARDS_TO_DISPLAY: () => TOTAL_NUMBER_OF_CARDS_TO_DISPLAY,
  },

  mounted() {
    this.createChallenge()
  },

  methods: {
    createChallenge() {
      this.numberOfGoodCardsToDisplay = pickIntegerBetween(2, 3)
      const numberOfBadCardsToDisplay =
        TOTAL_NUMBER_OF_CARDS_TO_DISPLAY - this.numberOfGoodCardsToDisplay

      const challengeConfig = {
        imagesEndpoint: IMAGES_ENDPOINT,
        totalNumberOfBadCards: TOTAL_NUMBER_OF_BAD_CARD_IMAGES,
        totalNumberOfGoodCards: TOTAL_NUMBER_OF_GOOD_CARD_IMAGES,
        numberOfBadCardsToDisplay,
        numberOfGoodCardsToDisplay: this.numberOfGoodCardsToDisplay,
      }

      this.cards = createCardsToDisplay(challengeConfig)
    },

    toggleCardSelection(cardId) {
      this.cards = this.cards.map((card) => {
        const needsToBeToggled = card.id === cardId

        return {
          ...card,
          isSelected: needsToBeToggled ? !card.isSelected : card.isSelected,
        }
      })
    },

    skipChallenge() {
      this.$emit('skip-challenge')
    },

    submitForm() {
      const numberOfGoodCardsVisible = this.numberOfGoodCardsToDisplay
      const isSuccessful = isSolved({
        cards: this.cards,
        numberOfGoodCardsVisible,
      })

      trackClick(TrackingData)
      this.$emit('submit-challenge', { isSuccessful })
    },

    handleLoadedCard(cardId) {
      this.loadedCards.push(cardId)

      if (this.loadedCards.length === this.TOTAL_NUMBER_OF_CARDS_TO_DISPLAY) {
        this.isLoading = false
      }
    },
  },
}
