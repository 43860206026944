
import { RevOverlay, RevToast } from '@backmarket/design-system'
import { mapGetters } from 'vuex'

import Challenge from './Challenge.vue'
import { TOAST_CATCHA, TOAST_VARIANT } from './catcha.constants'
import translations from './catcha.translations'

export default {
  components: {
    Challenge,
    RevOverlay,
    RevToast,
  },

  data() {
    return {
      isEnabled: true,
      toast: {
        name: TOAST_CATCHA,
        isVisible: false,
        title: '',
        text: '',
        variant: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      isCookieModalOpen: 'gdpr/cookies/isOpen',
      country: 'config/country',
    }),

    translations: () => translations,

    canBeDisplayed() {
      return (
        this.isEnabled &&
        this.$config.FF_CATCHA_BY_COUNTRY?.includes(this.country) &&
        !this.isCookieModalOpen
      )
    },
  },

  methods: {
    hideChallenge() {
      this.isEnabled = false
    },

    closeToast() {
      this.toast.isVisible = false
    },

    handleChallengeResult({ isSuccessful }) {
      this.toast.title = isSuccessful
        ? this.$t(translations.toastSuccessTitle)
        : this.$t(translations.toastFailureTitle)
      this.toast.text = isSuccessful
        ? this.$t(translations.toastSuccessText)
        : this.$t(translations.toastFailureText)
      this.toast.variant = isSuccessful
        ? TOAST_VARIANT.SUCCESS
        : TOAST_VARIANT.NEUTRAL

      this.toast.isVisible = true

      this.isEnabled = false
    },
  },
}
