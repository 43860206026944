import { CAT_CAMPAIGN_EVENT_PAGE } from '@campaigns/cat/catpaign.constants'

export const PAGES_WITH_CATCHA = [CAT_CAMPAIGN_EVENT_PAGE]

export { TOAST_CATCHA } from '@config/constants/toasts'

export const TOAST_VARIANT = {
  SUCCESS: 'success',
  NEUTRAL: 'neutral',
}

export const IMAGES_ENDPOINT =
  'https://d1eh9yux7w8iql.cloudfront.net/front/public/statics/cat-campaign'

export const TOTAL_NUMBER_OF_CARDS_TO_DISPLAY = 9
export const TOTAL_NUMBER_OF_BAD_CARD_IMAGES = 15
export const TOTAL_NUMBER_OF_GOOD_CARD_IMAGES = 12

export const TYPE = {
  GOOD: 'good',
  BAD: 'bad',
}

export const TrackingData = {
  zone: 'recatchat_modal',
  name: 'verify',
  page_type: 'event-page',
}
