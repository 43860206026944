export default {
  title: {
    id: 'catcha_title',
    defaultMessage: 'Save our site from being meow-jacked.',
  },
  directives: {
    id: 'catcha_directives',
    defaultMessage: 'Use your 100% human hand to select all non-cat images.',
  },
  verifyButton: {
    id: 'catcha_verify_button',
    defaultMessage: 'Verify',
  },
  reloadButtonLabel: {
    id: 'catcha_reload_button_label',
    defaultMessage: 'reload',
  },
  toastSuccessTitle: {
    id: 'catcha_toast_success_title',
    defaultMessage: 'Great!',
  },
  toastSuccessText: {
    id: 'catcha_toast_success_desc',
    defaultMessage: "You're a #1 cat detective 😎",
  },
  toastFailureTitle: {
    id: 'catcha_toast_fail_title',
    defaultMessage: 'Just wait a meowment...',
  },
  toastFailureText: {
    id: 'catcha_toast_fail_desc',
    defaultMessage: "JK, we'll survive. Come on in.",
  },
  toastClose: {
    id: 'toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  skipLink: {
    id: 'catcha_skip_link',
    defaultMessage: 'Skip',
  },
}
