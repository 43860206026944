
import { RevContainer } from '@backmarket/design-system'
import isEmpty from 'lodash/isEmpty'

import Breadcrumb from '@app/components/Breadcrumb'
import Catcha from '@campaigns/cat/catcha'
import { PAGES_WITH_CATCHA } from '@campaigns/cat/catcha/catcha.constants'
import {
  trackClick,
  trackLandingPage,
  trackProductClick,
} from '@tracking/events'

import { DEFAULT_BLOCK_TYPE } from '../../Landing.config'
import BlockRenderer from '../../components/BlockRenderer.vue'
import Heading from '../../components/Heading'
import HeroBanner from '../../components/HeroBanner'
import RichTextEditorParser from '../../components/RichTextEditorParser'
import { Container } from '../../components/private/Container'
import { intermediaryPath } from '../../helpers/breadcrumb'
import {
  HIDE_ON_DEVICE_CLASSES,
  SHOW_ON_DEVICE_CLASSES,
  getDataSelectorAttributeForSeo,
} from '../../helpers/constants'
import { isHiddenOnAllDevices } from '../../helpers/devices'
import { valueBuilder } from '../../helpers/tracking'

import { fullWidthComponents } from './Default.constants'

export default {
  components: {
    Catcha,
    Container,
    BlockRenderer,
    Breadcrumb,
    RevContainer,
    Heading,
    HeroBanner,
    RichTextEditorParser,
  },
  props: {
    pageType: {
      type: String,
      required: true,
    },
    parentPageInfos: {
      type: Object,
      default: () => {},
    },
    blocks: {
      type: Array,
      required: true,
    },
    textSeo: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    badge: {
      type: String,
      default: '',
    },
    titleBreadcrumb: {
      type: String,
      required: true,
    },
    banner: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    DEFAULT_BLOCK_TYPE: () => DEFAULT_BLOCK_TYPE,
    dataSelectorAttribute() {
      return getDataSelectorAttributeForSeo(this.pageType)
    },
    hasTextSeo() {
      return !isEmpty(this.textSeo)
    },
    hasHeroBanner() {
      return (
        !isEmpty(this.banner) &&
        !isHiddenOnAllDevices({ devices: this.banner.hiddenDevices })
      )
    },
    bannerHiddenByDevices() {
      return this.banner?.hiddenDevices || []
    },
    heroBannerClasses() {
      return this.bannerHiddenByDevices.map(
        (device) => HIDE_ON_DEVICE_CLASSES[device],
      )
    },
    titleClasses() {
      return this.bannerHiddenByDevices.map(
        (device) => SHOW_ON_DEVICE_CLASSES[device],
      )
    },
    displayTitle() {
      return !this.hasHeroBanner || !isEmpty(this.titleClasses)
    },
    intermediaryPath() {
      return intermediaryPath({
        parentPageInfos: this.parentPageInfos,
        pageType: this.pageType,
      })
    },
    trackingZone() {
      const { name, params } = this.$route

      return valueBuilder([name, params.pageName, params.pageCategory])
    },
    hasCatcha() {
      const {
        params: { pageName },
      } = this.$route

      return PAGES_WITH_CATCHA.includes(pageName)
    },
  },
  mounted() {
    const { name, params } = this.$route

    trackLandingPage({
      name: 'landing_page',
      landingType: name,
      landingName: valueBuilder([params.pageName, params.pageCategory]),
    })
  },
  methods: {
    sendTracking({ type, title }) {
      trackClick({
        zone: this.trackingZone,
        name: title,
        value: type,
      })
    },

    sendTrackingProductClickData(trackingData) {
      if (!isEmpty(trackingData)) {
        trackProductClick(trackingData)
      }
    },

    componentReference(block) {
      return block.props?.identifier || block.type
    },

    getBlockPosition(index) {
      return index + 1
    },

    isFullWidth(blockType) {
      return fullWidthComponents.includes(blockType)
    },
  },
}
