import { TYPE } from './catcha.constants'

function pickRandomIndexFrom(array) {
  return Math.floor(Math.random() * array.length)
}

function createRandomSubsetFrom({ array, length }) {
  const pickArray = [...array]
  const subset = []

  for (let i = 0; i < length; i += 1) {
    const randomItemIndex = pickRandomIndexFrom(pickArray)
    const item = pickArray.splice(randomItemIndex, 1)[0]
    subset.push(item)
  }

  return subset
}

function shuffle(array) {
  const { length } = array

  return createRandomSubsetFrom({ array, length })
}

// returns [ 0, 1, 2, ..., n ]
function createIntegersArrayOf(n) {
  return new Array(n).fill().map((_, index) => index)
}

function createAllCards({ imagesEndpoint, type, length }) {
  return new Array(length).fill().map((_, index) => {
    const imageUrl = `${imagesEndpoint}/${type}_${index}.jpg`

    return {
      id: `${type}_${index}`,
      imageUrl,
      type,
      isSelected: false,
    }
  })
}

export function pickIntegerBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function isSolved({ cards, numberOfGoodCardsVisible }) {
  const badCards = cards.filter((item) => item.type === TYPE.BAD)
  const goodCards = cards.filter((item) => item.type === TYPE.GOOD)

  const selectedBadCards = badCards.filter((item) => item.isSelected)
  const selectedGoodCards = goodCards.filter((item) => item.isSelected)

  const hasWrongAnswers = selectedBadCards.length
  const hasAllCorrectAnswers =
    selectedGoodCards.length === numberOfGoodCardsVisible

  return !hasWrongAnswers && hasAllCorrectAnswers
}

export function createCardsToDisplay({
  imagesEndpoint,
  totalNumberOfBadCards,
  totalNumberOfGoodCards,
  numberOfBadCardsToDisplay,
  numberOfGoodCardsToDisplay,
}) {
  const badCards = createAllCards({
    imagesEndpoint,
    type: TYPE.BAD,
    length: totalNumberOfBadCards,
  })
  const goodCardCards = createAllCards({
    imagesEndpoint,
    type: TYPE.GOOD,
    length: totalNumberOfGoodCards,
  })

  const badCardIndicesPool = createIntegersArrayOf(totalNumberOfBadCards)
  const goodCardCardIndicesPool = createIntegersArrayOf(totalNumberOfGoodCards)

  const badCardsIndicesSelected = createRandomSubsetFrom({
    array: badCardIndicesPool,
    length: numberOfBadCardsToDisplay,
  })
  const goodCardCardsIndicesSelected = createRandomSubsetFrom({
    array: goodCardCardIndicesPool,
    length: numberOfGoodCardsToDisplay,
  })

  const badCardsToDisplay = badCards.filter((_, index) =>
    badCardsIndicesSelected.includes(index),
  )
  const goodCardCardsToDisplay = goodCardCards.filter((_, index) =>
    goodCardCardsIndicesSelected.includes(index),
  )

  const cardsInOrder = [...badCardsToDisplay, ...goodCardCardsToDisplay]

  return shuffle(cardsInOrder)
}
